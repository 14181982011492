import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import footerdata from './footerdata.json'

const Footer = () => {
  useEffect(() => {
    const publicUrl = `${process.env.PUBLIC_URL}/`
    const minscript = document.createElement('script')
    minscript.async = true
    minscript.src = `${publicUrl}assets/js/main.js`

    document.body.appendChild(minscript)
  }, [])

  const publicUrl = `${process.env.PUBLIC_URL}/`
  const imgattr = 'Footer logo'

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="footer-top">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <a className="footer-logo" href="#">
                <img src={publicUrl + footerdata.footerlogo} alt={imgattr} />
              </a>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">{footerdata.quicklink.title}</h4>
                <ul>
                  {footerdata.quicklink.links.map((item, i) => (
                    <li className="readeal-top" key={i}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="footer-social text-sm-right">
                <span>FOLLOW US</span>
                <ul className="social-icon">
                  {footerdata.socialicon.map((item, i) => (
                    <li key={i}>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <i className={item.icon} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right text-center">{footerdata.copyrighttext}</div>
      </div>
    </footer>
  )
}

export default Footer
