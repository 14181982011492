import React, {Suspense, lazy} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

/* COMPONENTS */
import Navbar from './components/navbar/Navbar'
import Footer from './components/footer/Footer'
import Loading from './components/loading/Loading'

/* PAGES */
const Home = lazy(() => import('./pages/home/Home'))
const Contact = lazy(() => import('./pages/contacts/Contact'))
const About = lazy(() => import('./pages/about/About'))
const Error = lazy(() => import('./pages/error/Error'))

const Property = lazy(() => import('./pages/property/Property'))
const PropertyDetails = lazy(() => import('./pages/property/PropertyDetails'))
const Services = lazy(() => import('./pages/services/services'))

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Navbar />

      <Switch>
        {/* Default */}
        <Route exact path="/" component={Home} />

        <Route exact path="/property" component={Property} />
        <Route
          exact
          path="/property-details/:uuid"
          component={PropertyDetails}
        />

        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/404" component={Error} />

        <Route>
          <Redirect to="/404" />
        </Route>
      </Switch>

      <Footer />
    </Suspense>
  )
}

export default App
